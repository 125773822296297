<template>
  <draggable
    v-model="tasks"
    handle=".draggable-task-handle"
    tag="ul"
    class="todo-task-list media-list"
    :disabled="!checkPermission(['ALLOW_ALL', 'CHANGE_ORDER_TASK'])"
    @change="orderList(tasks)"
  >
    <!-- @change="" -->
    <li
      v-for="task in tasks"
      :key="task.hash"
      class="todo-item"
      :class="{ 'completed': task.is_completed }"
    >
      <feather-icon
        icon="MoreVerticalIcon"
        class="draggable-task-handle d-inline"
      />
      <div class="todo-title-wrapper">
        <div class="todo-title-area">
          <div class="title-wrapper">
            <b-form-checkbox
              v-if="checkPermission(['ALLOW_ALL', 'EDIT_TASK'])"
              :checked="task.is_completed"
              @click.native.stop
              @change="$emit('task-is-completed', task)"
            />
            <span
              class="todo-title"
              @click="$emit('task-show', task)"
            >{{ task.title }}</span>
          </div>
        </div>
        <div class="todo-item-action">
          <div class="badge-wrapper mr-1">
            <b-badge
              v-for="tag in task.tags"
              :key="tag.hash"
              pill
              :variant="`light-${tag.color}`"
              class="text-capitalize"
            >
              {{ tag.title }}
            </b-badge>
          </div>
          <small class="text-nowrap text-muted mr-1">
            {{ formatDate(task.due_date, { month: 'short', day: 'numeric'}) }}
          </small>
          <b-avatar
            v-if="task.user"
            size="32"
            :src="(task.user.avatar_media_file != null) ? `${path}/${task.user.avatar_media_file.url_relative}` : ''"
            :text="avatarText(`${task.user.name} ${task.user.first_name}`)"
          />
          <!-- :variant="task.tags | resolveTagVariant" -->
          <b-avatar
            v-else
            size="32"
            variant="light-secondary"
          >
            <feather-icon
              icon="UserIcon"
              size="16"
            />
          </b-avatar>
        </div>
      </div>
    </li>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'
import { mapActions } from 'vuex'
import { formatDate, avatarText } from '@core/utils/filter'
// import ListDragable from './ListDragable.vue'

export default {
  name: 'NestedDraggable',
  components: {
    draggable,
    // ListDragable,
  },
  props: {
    tasks: {
      required: true,
      type: Array,
    },
    path: {
      required: true,
      type: String,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      // property: 'value',
    }
  },
  methods: {
    ...mapActions({
      orderTask: 'todo/orderTask',
    }),
    formatDate,
    avatarText,
    async orderList(dataTask) {
      await this.orderTask(dataTask).then(() => {
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.draggable-task-handle {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
      // padding: 3px ;
    }
    .todo-item {
      padding: 3px !important;
    }
}
</style>

<style lang="scss">
@import "@core/scss/base/pages/app-todo.scss";
</style>
