<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div
            v-if="checkPermission(['ALLOW_ALL', 'CREATE_TASK'])"
            class="add-task"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              :style="colorPrimaryBtn"
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              {{ $t('add-task') }}
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ $t(filter.title) }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div
              v-if="checkPermission(['ALLOW_ALL', 'CREATE_TASK', 'EDIT_TASK'])"
              class="mt-3 px-2 d-flex justify-content-between cursor-pointer"
              @click="showTagModal = true"
            >
              <h6 class="section-label mb-1">
                {{ $t('tags') }}
              </h6>
              <feather-icon
                icon="PlusIcon"
              />
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in tags"
                :key="tag.title + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                class="w-100"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <!-- {{tag.route}} -->
                <span class="text-capitalize">{{ tag.title }}</span>
                <!-- <div class="d-flex"> -->

                <!-- Menu -->
                <!-- <small class="mail-date-time text-muted">{{ formatDate(message.time) }}</small> -->
                <!-- Mail Action DD -->
                <b-dropdown
                  v-if="checkPermission(['ALLOW_ALL', 'DELETE_TASK'])"
                  class="float-right"
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="float-right text-body"
                    />
                  </template>

                  <b-dropdown-item @click="onDeleteTag(tag)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{ $t('buttons.delete') }}</span>
                    <!-- Delete -->
                  </b-dropdown-item>
                </b-dropdown>
                <!-- </div> -->
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
      <tag-modal
        :color-primary-btn="colorPrimaryBtn"
        :show-tag-modal="showTagModal"
        @show-modal="handleModal"
        @add="onAddTag"
      />
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'
import checkPermission from '@/auth/permissions'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import TagModal from './TagModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    TagModal,
  },
  data() {
    return {
      showTagModal: false,
      value: [
        'team',
        'low',
        'medium',
        'high',
        'update',
      ],
    }
  },
  computed: {
    ...mapGetters({
      colorPrimaryBtn: 'colorPrimaryBtn',
      clubInfo: 'clubInfo',
      tags: 'tags/list',
    }),
  },
  created() {
    this.fetchTags()
  },
  mounted() {
  },
  methods: {
    checkPermission,
    handleModal() {
      this.showTagModal = false
    },
    ...mapActions({
      addTag: 'tags/addTag',
      fetchTags: 'tags/fetchTags',
      removeTag: 'tags/removeTag',
    }),
    async onAddTag(tagData) {
      if (!this.checkPermission(['ALLOW_ALL', 'ALLOW_OSTRAIL'])) {
        tagData.club_hash = this.clubInfo.hash
      }
      await this.addTag(tagData).then(async () => {
        await this.fetchTags()
      }).catch(error => {
        this.responseCatch(error)
      })
    },
    async onDeleteTag(tagData) {
      await this.removeTag(tagData.hash).then(() => {
        this.fetchTags()
        this.$emit('get-tasks')
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'my-task', icon: 'MailIcon', route: { name: 'todo' } },
      { title: 'important', icon: 'StarIcon', route: { name: 'todo-filter', params: { filter: 'important' } } },
      { title: 'completed', icon: 'CheckIcon', route: { name: 'todo-filter', params: { filter: 'completed' } } },
      { title: 'deleted', icon: 'TrashIcon', route: { name: 'todo-filter', params: { filter: 'deleted' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
