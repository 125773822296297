<template>
  <div>

    <!-- <direct-access-card
      color="primary"
      icon="icon-0-icons-dark-plus"
      :title="$t('generic.add')"
      :no-action="true"
    /> -->
    <b-modal
      id="createDirectAccessModal"
      :visible="showTagModal"
      centered
      ok-only
      :title="$t('generic.tag')"
      no-close-on-backdrop
      :ok-title="$t('buttons.save')"
      @close="handleClose()"
    >
      <validation-observer
        ref="formTags"
        v-slot="{ }"
      >
        <b-form>

          <!-- Router -->
          <b-form-group>
            <label for="name">{{ $t('generic.name') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required|tagsRegex"
              name="name"
            >
              <b-form-input
                id="task-title"
                v-model="form.title"
                autofocus
                :state="errors.length > 0 ? false : null"
                trim
                :placeholder="$t('generic.name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Color -->
          <b-form-group>
            <label for="name">{{ $t('color') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="color"
            >
              <v-select
                v-model="form.color"
                label="name"
                :placeholder="$t('color')"
                :options="variants"
                :reduce="(item) => item.name"
              >
                <template #option="{ name }">
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${name}`"
                  />
                  <span class="ml-50 d-inline-block align-middle"> {{ $t(name) }}</span>
                </template>

                <template #selected-option="{ name }">
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${name}`"
                  />

                  <span class="ml-50 d-inline-block align-middle"> {{ $t(name) }}</span>
                </template>

                <!-- Not item found -->
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>

      </validation-observer>
      <template #modal-footer>
        <div class="float-right">
          <b-button
            variant="secondary"
            pill
            @click="handleClose"
          >
            <!-- @click="resetForm" -->
            {{ $t('buttons.cancel') }}
          </b-button>

          <b-button
            variant="primary"
            style="margin-left: 17px;"
            pill
            :style="colorPrimaryBtn"
            @click="handleDirectAccess"
          >
            {{ $t('buttons.save') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import checkPermission from '@/auth/permissions'
// import searchAndBookmarkData from '@/@core/layouts/components/app-navbar/search-and-bookmark-data'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import DirectAccessCard from './DirectAccessCard.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // DirectAccessCard,
    vSelect,
  },
  props: {
    showTagModal: {
      type: Boolean,
      defalut: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // showTagModal: false,
      variants: [{ name: 'dark' }, { name: 'primary' }, { name: 'secondary' }, { name: 'success' }, { name: 'danger' }, { name: 'warning' }, { name: 'info' }],
      form: {
        title: '',
        color: 'primary',
        route: { name: 'todo-tag', params: { tag: 'team' } },
      },
    }
  },
  watch: {
  },
  methods: {
    checkPermission,
    handleClose() {
      this.showTagModal = false
      this.$emit('show-modal', false)
      this.clearForm()
    },
    handleDirectAccess() {
      this.$refs.formTags.validate().then(isValid => {
        if (isValid) {
          this.form.route.params.tag = this.form.title
          this.$emit('add', this.form)
          this.handleClose()
          this.clearForm()
        }
      })
    },
    clearForm() {
      this.form = {
        title: null,
        color: 'primary',
        route: { name: 'todo-tag', params: { tag: 'team' } },
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.mr-20 {
  margin-right: 20px !important;
}
</style>
