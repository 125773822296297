var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"createDirectAccessModal","visible":_vm.showTagModal,"centered":"","ok-only":"","title":_vm.$t('generic.tag'),"no-close-on-backdrop":"","ok-title":_vm.$t('buttons.save')},on:{"close":function($event){return _vm.handleClose()}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"secondary","pill":""},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),_c('b-button',{staticStyle:{"margin-left":"17px"},style:(_vm.colorPrimaryBtn),attrs:{"variant":"primary","pill":""},on:{"click":_vm.handleDirectAccess}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")])],1)]},proxy:true}])},[_c('validation-observer',{ref:"formTags",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',[_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('generic.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|tagsRegex","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"task-title","autofocus":"","state":errors.length > 0 ? false : null,"trim":"","placeholder":_vm.$t('generic.name')},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t('color'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"color"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":_vm.$t('color'),"options":_vm.variants,"reduce":function (item) { return item.name; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"bullet bullet-sm mr-1",class:("bullet-" + name)}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(_vm.$t(name)))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"bullet bullet-sm mr-1",class:("bullet-" + name)}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(_vm.$t(name)))])]}}],null,true),model:{value:(_vm.form.color),callback:function ($$v) {_vm.$set(_vm.form, "color", $$v)},expression:"form.color"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }