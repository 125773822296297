var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-task-handler","sidebar-class":"sidebar-lg","visible":_vm.isTaskHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-task-handler-sidebar-active', val); },"hidden":_vm.clearForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.taskLocal.hash)?_c('b-button',{attrs:{"size":"sm","variant":_vm.taskLocal.is_completed ? 'outline-success' : 'outline-secondary',"disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},on:{"click":function($event){_vm.taskLocal.is_completed = !_vm.taskLocal.is_completed}}},[_vm._v(" "+_vm._s(_vm.taskLocal.is_completed ? _vm.$t('completed') : _vm.$t('mark-complete'))+" ")]):_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('add-task'))+" ")]),_c('div',[(Boolean(_vm.taskLocal.allow_assigned) && _vm.taskLocal.user_hash !== _vm.userIdAuth)?_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.taskLocal.hash),expression:"taskLocal.hash"}],staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon","disabled":!_vm.checkPermission(['ALLOW_ALL', 'DELETE_TASK']) && _vm.taskLocal.hash != null},on:{"click":function($event){_vm.$emit('remove-task'); hide();}}}):_vm._e(),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",class:{ 'text-warning': _vm.taskLocal.is_important },attrs:{"icon":"StarIcon","size":"16","disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},on:{"click":function($event){_vm.taskLocal.is_important = !_vm.taskLocal.is_important}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('label',{attrs:{"for":"task-title"}},[_vm._v(_vm._s(_vm.$t('generic.title'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"task-title","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":_vm.$t('generic.title'),"disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},model:{value:(_vm.taskLocal.title),callback:function ($$v) {_vm.$set(_vm.taskLocal, "title", $$v)},expression:"taskLocal.title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),(_vm.taskLocal.user_hash !== _vm.userIdAuth)?_c('b-form-group',[_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"id":"checkbox-1","value":true,"unchecked-value":false,"disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},model:{value:(_vm.taskLocal.allow_assigned),callback:function ($$v) {_vm.$set(_vm.taskLocal, "allow_assigned", $$v)},expression:"taskLocal.allow_assigned"}},[_vm._v(" "+_vm._s(_vm.$t('assign-to'))+" ")])],1):_vm._e(),(Boolean(_vm.taskLocal.allow_assigned) && _vm.taskLocal.user_hash !== _vm.userIdAuth)?_c('b-form-group',[_c('label',{attrs:{"for":"assignee"}},[_vm._v(_vm._s(_vm.$t('assignee'))+" ")]),_c('v-select',{staticClass:"assignee-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userList,"reduce":function (option) { return option.hash; },"label":"full_name","input-id":"assignee","disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var full_name = ref.full_name;
return [_c('span',{staticClass:"d-inline-block align-middle"},[_vm._v(" "+_vm._s(full_name))])]}},{key:"selected-option",fn:function(ref){
var full_name = ref.full_name;
return [_c('span',{staticClass:"d-inline-block align-middle"},[_vm._v(" "+_vm._s(full_name))])]}}],null,true),model:{value:(_vm.taskLocal.user_hash),callback:function ($$v) {_vm.$set(_vm.taskLocal, "user_hash", $$v)},expression:"taskLocal.user_hash"}})],1):_vm._e(),_c('validation-provider',{attrs:{"name":"dueDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('label',{attrs:{"for":"date"}},[_vm._v(_vm._s(_vm.$t('due-date'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":_vm.$i18n.locale,"min":new Date(),"placeholder":_vm.$t('member.form.placeholder.selectDate'),"disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},model:{value:(_vm.taskLocal.due_date),callback:function ($$v) {_vm.$set(_vm.taskLocal, "due_date", $$v)},expression:"taskLocal.due_date"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',[_c('label',{attrs:{"for":"tag"}},[_vm._v(_vm._s(_vm.$t('tags'))+" ")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","close-on-select":false,"label":"title","options":_vm.tags,"input-id":"tags","disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
var color = ref.color;
return [_c('span',{staticClass:"bullet bullet-sm mr-1",class:("bullet-" + color)}),_c('span',{staticClass:"d-inline-block align-middle text-capitalize"},[_vm._v(" "+_vm._s(title))])]}},{key:"selected-option",fn:function(ref){
var title = ref.title;
var color = ref.color;
return [_c('span',{staticClass:"bullet bullet-sm mr-1",class:("bullet-" + color)}),_c('span',{staticClass:"d-inline-block align-middle text-capitalize"},[_vm._v(" "+_vm._s(title))])]}}],null,true),model:{value:(_vm.taskLocal.tags),callback:function ($$v) {_vm.$set(_vm.taskLocal, "tags", $$v)},expression:"taskLocal.tags"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('generic.noRecordsFound'))+" ")])])],1),_c('b-form-group',{attrs:{"label":_vm.$t('generic.description'),"label-for":"task-description"}},[_c('b-form-textarea',{attrs:{"id":"task-description","maxlength":"254","disabled":!_vm.checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && _vm.taskLocal.hash != null},model:{value:(_vm.taskLocal.description),callback:function ($$v) {_vm.$set(_vm.taskLocal, "description", $$v)},expression:"taskLocal.description"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"pill":"","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")]),(_vm.applyAction(_vm.taskLocal.hash))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",style:(_vm.colorPrimaryBtn),attrs:{"pill":"","variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.taskLocal.hash ? _vm.$t('buttons.update') : _vm.$t('buttons.add'))+" ")]):_vm._e()],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }