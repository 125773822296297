<!-- eslint-disable import/no-cycle -->
<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="todo-app-list">
      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">
        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>
        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              type="text"
              :placeholder="$t('search-task')"
              debounce="1000"
            />
            <!--@input="updateRouteQuery"-->
          </b-input-group>
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="tasks"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
          :disabled="!checkPermission(['ALLOW_ALL', 'CHANGE_ORDER_TASK'])"
          @change="orderTask(tasks)"
        >
          <li
            v-for="(task, index) in tasks"
            :key="task.hash"
            :class="!task.is_completed ? 'todo-item' : 'todo-item completed'"
            :style="index > 0 ? 'border-top: rgba(106, 190, 255, 0.5) 1px solid;' : ''"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                    v-if="checkPermission(['ALLOW_ALL', 'EDIT_TASK'])"
                    :checked="task.is_completed"
                    @click.native.stop
                    @change="updateTaskIsCompleted(task)"
                  />
                  <span
                    class="todo-title"
                    @click="handleTaskClick(task)"
                  >{{ task.title }}
                  </span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                    v-for="tag in task.tags"
                    :key="tag.hash"
                    pill
                    :variant="`light-${tag.color}`"
                    class="text-capitalize"
                  >
                    {{ tag.title }}
                    <!-- {{ $t(tag) }} -->
                  </b-badge>
                </div>
                <small class="text-nowrap text-muted mr-1">
                  {{ formatDate(task.due_date, { month: 'short', day: 'numeric'}) }}
                </small>
                <!-- <small class="text-nowrap text-muted mr-1">{{ task.due_date | formatDate }}</small> -->
                <b-avatar
                  v-if="task.user"
                  size="32"
                  :src="(task.user.avatar_media_file != null) ? `${path}/${task.user.avatar_media_file.url_relative}` : ''"
                  :text="avatarText(`${task.user.name} ${task.user.first_name}`)"
                />
                <!-- :variant="task.tags | resolveTagVariant" -->
                <b-avatar
                  v-else
                  size="32"
                  variant="light-secondary"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="16"
                  />
                </b-avatar>
                <b-button
                  v-if="checkPermission(['ALLOW_ALL', 'CREATE_TASK'])"
                  variant="primary"
                  class="ml-1 btn-icon rounded-circle"
                  @click="addSubTask(task)"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
            </div>
            <list-dragable
              :tasks.sync="task.tasks"
              :check-permission="checkPermission"
              :path="path"
              @task-is-completed="updateTaskIsCompleted"
              @task-show="handleTaskClick"
            />
            <!-- <div>{{task.tasks}}</div> -->

          </li>
        </draggable>
        <!-- <list-dragable :tasks="task.tasks" /> -->
        <div
          class="no-results"
          :class="{'show': !tasks.length}"
        >
          <h5>{{ $t('generic.noRecordsFound') }}</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <todo-task-handler-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :clear-task-data="clearTaskData"
      :check-permission="checkPermission"
      @remove-task="removeTask"
      @add-task="addTask"
      @update-task="updateTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
        :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false, subtaskHash = null"
        @get-tasks="fetchTasks"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import checkPermission from '@/auth/permissions'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import { ref, watch, computed } from '@vue/composition-api'
import { BFormInput, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
// eslint-disable-next-line import/no-cycle
import { formatDate, avatarText } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
// import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import ListDragable from './ListDragable.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    // BDropdown,
    // BDropdownItem,
    draggable,
    VuePerfectScrollbar,
    ListDragable,

    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  computed: {
    ...mapGetters({
      path: 'path',
    }),
  },
  setup() {
    const { route, router } = useRouter()

    if (checkPermission(['VIEW_TASK'])) {
      const routeSortBy = computed(() => route.value.query.sort)
      const routeQuery = computed(() => route.value.query.q)
      const routeParams = computed(() => route.value.params)
      const notifications = computed(() => store.state.notificationStore.total)

      watch(routeParams, () => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
      })

      watch(notifications, () => {
        // eslint-disable-next-line no-use-before-define
        fetchTasks()
      })

      const tasks = ref([])

      const sortOptions = [
        'latest',
        'asc',
        'desc',
        'assignee',
        'due_date',
      ]

      const sortBy = ref(routeSortBy.value)

      watch(routeSortBy, val => {
        if (sortOptions.includes(val)) sortBy.value = val
        else sortBy.value = val
      })

      const resetSortAndNavigate = () => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        delete currentRouteQuery.sort

        router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
      }

      const blankTask = {
        hash: null,
        title: '',
        due_date: new Date(),
        description: '',
        assignee: null,
        tags: [],
        is_completed: false,
        is_deleted: false,
        is_important: false,
        allow_assigned: false,
        user: null,
        user_hash: null,
        user_created_hash: null,
        task_hash: null,
      }

      const subtaskHash = ref(Number)

      const task = ref(JSON.parse(JSON.stringify(blankTask)))

      const clearTaskData = () => {
        task.value = JSON.parse(JSON.stringify(blankTask))
      }

      const addTask = val => {
        val.due_date = new Date(val.due_date)
        if (subtaskHash.value) {
          val.task_hash = subtaskHash.value
        }
        // this.clubInfo.hash
        store.dispatch('todo/addTask', val).then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
      }

      const removeTask = () => {
        store.dispatch('todo/removeTask', { id: task.value.hash }).then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
      }

      const updateTask = taskData => {
        store.dispatch('todo/updateTask', { task: taskData }).then(() => {
          // eslint-disable-next-line no-use-before-define
          fetchTasks()
        })
      }

      const orderTask = taskData => {
        store.dispatch('todo/orderTask', taskData).then(() => {})
      }

      const perfectScrollbarSettings = {
        maxScrollbarLength: 150,
      }

      const isTaskHandlerSidebarActive = ref(false)

      // Search Query
      const searchQuery = ref(routeQuery.value)

      watch(routeQuery, val => {
        searchQuery.value = val
      })

      // eslint-disable-next-line no-use-before-define
      watch([searchQuery, sortBy], () => fetchTasks())

      const updateRouteQuery = val => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      }

      const fetchTasks = () => {
        const filtersObj = []
        if (router.currentRoute.params.filter === 'important') {
          filtersObj.is_important = 1
        }
        if (router.currentRoute.params.filter === 'completed') {
          filtersObj.is_completed = 1
        }
        if (router.currentRoute.params.filter === 'deleted') {
          filtersObj.is_deleted = 2
        }
        if (router.currentRoute.params.tag) {
          filtersObj.tags = router.currentRoute.params.tag
          // filtersObj.tags_children = router.currentRoute.params.tag
        }
        if (searchQuery.value) {
          filtersObj.title = searchQuery.value
        }
        // if (sortBy.value === 'due_date') {
        //   filtersObj.due_date = 'asc'
        // }
        // sortBy: sortBy.value,
        store.dispatch('todo/fetchTasks', {
          filtersObj,
        }).then(response => {
          tasks.value = response.data.data
        })
      }

      fetchTasks()

      const handleTaskClick = taskData => {
        task.value = taskData
        subtaskHash.value = null
        isTaskHandlerSidebarActive.value = true
      }

      const addSubTask = taskData => {
        subtaskHash.value = taskData.hash
        isTaskHandlerSidebarActive.value = true
      }

      // Single Task is_completed update
      const updateTaskIsCompleted = taskData => {
        // eslint-disable-next-line no-param-reassign
        taskData.is_completed = !taskData.is_completed
        updateTask(taskData)
      }

      const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

      return {
        checkPermission,
        task,
        tasks,
        removeTask,
        addTask,
        updateTask,
        orderTask,
        clearTaskData,
        searchQuery,
        fetchTasks,
        perfectScrollbarSettings,
        updateRouteQuery,
        resetSortAndNavigate,
        // UI
        isTaskHandlerSidebarActive,
        // Click Handler
        handleTaskClick,
        // Filters
        formatDate,
        avatarText,
        // Single Task is_completed update
        updateTaskIsCompleted,
        // Left Sidebar Responsive
        mqShallShowLeftSidebar,
        addSubTask,
        subtaskHash,
        // tagsList,
      }
    }

    return router.push({ name: 'not-authorized' })
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "@core/scss/base/pages/app-todo.scss";
</style>
