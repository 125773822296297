<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <b-button
            v-if="taskLocal.hash"
            size="sm"
            :variant="taskLocal.is_completed ? 'outline-success' : 'outline-secondary'"
            :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
            @click="taskLocal.is_completed = !taskLocal.is_completed"
          >
            {{ taskLocal.is_completed ? $t('completed') : $t('mark-complete') }}
          </b-button>
          <h5
            v-else
            class="mb-0"
          >
            {{ $t('add-task') }}
          </h5>
          <div>
            <feather-icon
              v-if="Boolean(taskLocal.allow_assigned) && taskLocal.user_hash !== userIdAuth"
              v-show="taskLocal.hash"
              icon="TrashIcon"
              class="cursor-pointer"
              :disabled="!checkPermission(['ALLOW_ALL', 'DELETE_TASK']) && taskLocal.hash != null"
              @click="$emit('remove-task'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.is_important }"
              :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
              @click="taskLocal.is_important = !taskLocal.is_important"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group>
                <label for="task-title">{{ $t('generic.title') }} <span class="text-danger">*</span> </label>
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('generic.title')"
                  :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <!-- {{ taskLocal }} -->
              </b-form-group>
            </validation-provider>
            <!-- allow assigned -->
            <b-form-group
              v-if="taskLocal.user_hash !== userIdAuth"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="taskLocal.allow_assigned"
                :value="true"
                :unchecked-value="false"
                :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
                class="custom-control-primary"
              >
                {{ $t('assign-to') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- Assignee -->
            <b-form-group
              v-if="Boolean(taskLocal.allow_assigned) && taskLocal.user_hash !== userIdAuth"
            >
              <label for="assignee">{{ $t('assignee') }} </label>
              <v-select
                v-model="taskLocal.user_hash"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userList"
                :reduce="(option) => option.hash"
                label="full_name"
                class="assignee-selector"
                input-id="assignee"
                :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
              >
                <!-- :options="assigneeOptions" -->

                <template #option="{ full_name }">
                  <!-- <b-avatar
                    size="26"
                    :src="avatar"
                  /> -->
                  <span class="d-inline-block align-middle"> {{ full_name }}</span>
                </template>

                <template #selected-option="{ full_name }">
                  <!-- <b-avatar
                    size="26"
                    :src="avatar"
                    :variant="taskLocal.tags | resolveTagVariant"
                    :text="avatarText(full_name)"
                  /> -->

                  <span class="d-inline-block align-middle"> {{ full_name }}</span>
                </template>
              </v-select>
            </b-form-group>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              name="dueDate"
              rules="required"
            >

              <b-form-group>
                <label for="date">{{ $t('due-date') }} <span class="text-danger">*</span> </label>
                <b-form-datepicker
                  v-model="taskLocal.due_date"
                  :locale="$i18n.locale"
                  :min="new Date()"
                  class="form-control"
                  :placeholder="$t('member.form.placeholder.selectDate')"
                  :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--Tag -->
            <b-form-group>
              <label for="tag">{{ $t('tags') }} </label>
              <v-select
                v-model="taskLocal.tags"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                label="title"
                :options="tags"
                input-id="tags"
                :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
              >
                <!-- :reduce="option => option.title" -->
                <template #option="{ title, color }">
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${color}`"
                  />
                  <span class="d-inline-block align-middle text-capitalize"> {{ title }}</span>
                </template>

                <template #selected-option="{ title, color }">
                  <span
                    class="bullet bullet-sm mr-1"
                    :class="`bullet-${color}`"
                  />

                  <span class="d-inline-block align-middle text-capitalize"> {{ title }}</span>
                </template>

                <!-- Not item found -->
                <div slot="no-options">
                  {{ $t('generic.noRecordsFound') }}
                </div>
              </v-select>
            </b-form-group>

            <!-- Description -->
            <b-form-group
              :label="$t('generic.description')"
              label-for="task-description"
            >
              <b-form-textarea
                id="task-description"
                v-model="taskLocal.description"
                maxlength="254"
                :disabled="!checkPermission(['ALLOW_ALL', 'EDIT_TASK']) && taskLocal.hash != null"
              />
            </b-form-group>
            <!-- <b-form-group>
              <label for="task-description">{{ $t('generic.description') }} </label>
              <quill-editor
                id="quil-content"
                v-model="taskLocal.description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              > -->
            <!-- Add a bold button -->
            <!-- <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group> -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                pill
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-button
                v-if="applyAction(taskLocal.hash)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                pill
                variant="primary"
                class="ml-2"
                type="submit"
                :style="colorPrimaryBtn"
              >
                {{ taskLocal.hash ? $t('buttons.update') : $t('buttons.add') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
// import { quillEditor } from 'vue-quill-editor'
import clubsService from '@/services/clubsService'
import userService from '@/services/userService'
import useTaskHandler from './useTaskHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    // BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    // flatPickr,
    // quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      userList: [],
    }
  },
  computed: {
    ...mapGetters({
      // userList: 'userStore/userList',
      tags: 'tags/list',
      clubInfo: 'clubInfo',
      colorPrimaryBtn: 'colorPrimaryBtn',
      userIdAuth: 'id',
    }),
  },
  created() {
    this.getUsers()
  },
  methods: {
    ...mapActions({
      // getUsersData: 'userStore/getUsersData',
    }),
    applyAction(pTask) {
      return (pTask && this.checkPermission(['ALLOW_ALL', 'EDIT_TASK'])) || (!pTask && this.checkPermission(['ALLOW_ALL', 'CREATE_TASK']))
    },
    async getUsers() {
      if (this.clubInfo.hash) {
        await this.getUsersClub()
      } else {
        await this.getUsersOstrail()
      }
    },
    async getUsersClub() {
      this.isLoading = true
      await clubsService.getUsersByClubId(this.clubInfo.hash).then(response => {
        this.userList = response.data.data.users.filter(user => user.hash !== this.userIdAuth)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      })
    },
    async getUsersOstrail() {
      this.isLoading = true
      await userService.getAdministratorUsers().then(response => {
        this.userList = response.data.data.filter(user => user.hash !== this.userIdAuth)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      })
    },
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)
    // } = formValidation(props.clearTaskData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: '',
    }

    return {
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,

      // Form Validation
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_include.scss';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
